import { render, staticRenderFns } from "./ibc_score_info.vue?vue&type=template&id=a83b8b66&scoped=true&"
import script from "./ibc_score_info.vue?vue&type=script&lang=js&"
export * from "./ibc_score_info.vue?vue&type=script&lang=js&"
import style0 from "./ibc_score_info.vue?vue&type=style&index=0&id=a83b8b66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a83b8b66",
  null
  
)

export default component.exports